import { getCategories } from "../../apis/httpcom";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchCategories = createAsyncThunk(
  "appCategories/fetchCategories",
  async (params) => { 
    const jsonData = await getCategories();  
    return jsonData;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    count: 0,
    isLoading: false,
  },
  reducers: {
    setCategories(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.data = action.payload;
      state.count = action.payload.length ?? 0; 
    });
  },
});

export const { setCategories } = categorySlice.actions;
export default categorySlice.reducer;
