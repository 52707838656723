import { SERVER, fetchAllOrders, fetchGet, getUsers } from "../../apis/httpcom";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchUsers = createAsyncThunk(
  "appUsers/fetchUsers",
  async (params) => {
    // const jsonData = await getUsers();
    // return jsonData;

    return null;
  }
);

export const fetchOrders = createAsyncThunk(
  "appUsers/fetchOrders",
  async (params) => { 

    const url =
      SERVER +
      `/api/order?pageNumber=${params.page}&pageSize=${params.pageSize}&q=${params.q}`; 

    const response = await fetchAllOrders(url);
    const paginationHeader = JSON.parse(response.headers.get("Pagination"));

    const data = await response.json();

    return {
      items: data,
      pagination: paginationHeader,
    };
  }
);

export const getOrder = createAsyncThunk(
  "appOrder/getOrder",
  async (id, { getState, dispatch }) => { 

    const response = await fetchGet(SERVER + "/api/order/" + id);
    const data = await response.json(); 

    return data;
  }
);




const updateState = state => {
  if (state.orders.length === 0) {
    state.counters.total = 0

    return
  }

  state.counters.total = state.orders.length
}

const updatePaginationState = (state, data) => { 
  state.pagination.currentPage = data.currentPage
  state.pagination.itemsPerPage = data.itemsPerPage
  state.pagination.totalItems = data.totalItems
  state.pagination.totalPages = data.totalPages 
}

const counters = {
  total: 0,
}


const pagination = {
  currentPage: 0,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0
}

const paginationModel = { page: 0, pageSize: 5 }

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    hasLoggedIn: false,
    recentOrderId: '',
    orders: [],
    singleOrder: {},
    status: '',
    totalStr: 'orders',
    counters: counters,
    pagination: pagination,
    paginationModel: paginationModel,
  },
  reducers: {
    updateParams:( state, action) => {
      state.params = action.payload
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload
    },
    setLoadingState(state, action) {
      state.isLoading = action.payload;
    },
    setLoginStatus(state, action) {
      state.hasLoggedIn = action.payload;
    },
    setRecentOrder(state, action) {
      state.recentOrderId = action.payload;
    },
    handleSingleOrderReset(state, action) {
      state.singleOrder = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (state, action) => {

      state.status = 'succeeded'
      state.orders = action.payload.items
      if(action.payload.pagination){
        updatePaginationState(state, action.payload.pagination)
      }
      updateState(state)
    })
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.data = action.payload;
    })
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.singleOrder = action.payload;
    });
  },
});

export const { setLoginStatus, setRecentOrder, setLoadingState, updateParams, setPaginationModel, handleSingleOrderReset} = userSlice.actions;
export default userSlice.reducer;
