import { useAuth } from './hooks/useAuth'

const { Navigate } = require('react-router')

const PrivateRoute = ({ children }) => {
  const authContext = useAuth()
  const isAuthenticated = authContext.validateSession() 
  return isAuthenticated ? children : <Navigate to='/' />
}

export default PrivateRoute
