import { getProducts } from "../../apis/httpcom";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchProducts = createAsyncThunk(
  "appProducts/fetchProducts",
  async (params) => { 

    const jsonData = await getProducts(); 

    return jsonData;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    count: 0,
    isLoading: false,
    quickSearchData: [],
  },
  reducers: {
    setProducts(state, action) {
      state.data = action.payload;
    },
    setQuickSearchData(state, action) {
      state.quickSearchData = action.payload; 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.count = action.payload.length ?? 0; 
    });
  },
});

export const { setProducts, setQuickSearchData } = productSlice.actions;
export default productSlice.reducer;
