import authConfig from '../../src/config/auth'

//export const SERVER = 'http://localhost:5184'
export const SERVER = 'https://www.thepumpkinindia.com'

export const IMAGE_URL = SERVER + '/pumpkin-store/'

export async function performLogin(useremail, password) {
  const requestBody = {
    email: useremail,
    password: password
  }

  const response = await fetchPostAnonymous(SERVER + '/api/Account/login', requestBody)

  // if (!(await response).ok) {
  //   throw new Error('Could not authenticate user.')
  // }

  const data = await response.json()

  return data
}

export async function performRegister(firstname, lastname, useremail, password) {
  const requestBody = {
    FirstName: firstname,
    LastName: lastname,
    email: useremail,
    password: password,
    AppUserType: 1
  }

  const response = await fetchPostAnonymous(SERVER + '/api/Account/register', requestBody) 
  const data = await response.json()

  return data
}

export async function changePassword(email, curPw, password) {
  const requestBody = {
    email: email,
    currentpassword: curPw,
    newpassword: password
  }

  console.log(requestBody);

  const response = await fetchPost(SERVER + '/api/Account/changepassword', requestBody)

  if (!(await response).ok) {
    throw new Error('Could not change password.')
  }

  const data = await response.json()

  return data
}

export async function changeProfileDetails(requestBody) { 
  console.log(requestBody);

  const response = await fetchPost(SERVER + '/api/Account/changeaddress', requestBody)

  if (!(await response).ok) {
    throw new Error('Could not update account details.')
  }

  const data = await response.json()
  return data
}

export async function validateSession() {
  const response = await fetchGet(SERVER + '/api/account')

  if (!(await response).ok) {
    throw new Error('Could not authenticate user.')
  }

  const data = await response.json()

  return data
}

export async function getProducts() {
  const response = await fetchGet(SERVER + '/api/product')
  if (!(await response).ok) {
    console.log('failed to get product')
  }

  const data = await response.json()
  console.log("getProducts", data)

  return data
}
  
export async function getCategories() {
  const response = await fetchGet(SERVER + '/api/category')
  if (!(await response).ok) { 
  }

  const data = await response.json() 

  return data
}

export async function fetchAllOrders(url) {
  const response = await fetchGet(url)
  if (!(await response).ok) {
    console.log('failed to get orders')
  }

  return response
}
  

const fetchPostAnonymous = (url, requestBody) => {
  const response = fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(requestBody)
  })

  return response
}

export const fetchPost = (url, requestBody) => { 

  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)
  console.log('token', token)

  const response = fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(requestBody)
  })

  return response
}

export const fetchPatch = (url, requestBody) => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)

  const response = fetch(url, {
    method: 'PATCH',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(requestBody)
  })

  return response
}

export const fetchPostForm = (url, requestBody) => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)

  console.log(JSON.stringify(requestBody))

  const response = fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token
    }),
    body: requestBody
  })

  return response
}

export const fetchGet = url => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)

  const response = fetch(url, {
    headers: new Headers({
      Authorization: 'Bearer ' + token,      
    })
  })

  return response
}

const printResponse = response => {
  console.log('Response starts here')
  console.log(response)
  console.log('Response ends here')
}
