// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Config
import authConfig from '../../src/config/auth'
import { changePassword, changeProfileDetails, performLogin, performRegister, validateSession } from '../apis/httpcom'
 
 
// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  token: String,
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  changeProfileDetails: () => Promise.resolve(),
  validateSession: () => Boolean
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)

  // ** Hooks
  //const navigate = useNavigate();

  useEffect(() => {
    const initAuth = async () => { 

      const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)

      if (storedToken) {
        //setLoading(true)

        try {
          const res = await validateSession()
          if (res && res.error === 0) {
            setLoading(false)

            //set token
            window.localStorage.setItem(authConfig.storageTokenKeyName, res.token)

            //set user
            setUser({ ...res.userData }) 
          }
        } catch (err) { 
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          setUser(null);

          setLoading(false);
          //if (authConfig.onTokenExpiration === 'logout' && !router.pathname.includes('login'))

          // if (authConfig.onTokenExpiration === "logout") {
          //   navigate("/pages/login");
          // }
        }
      } else {
        setLoading(false)
        //navigate('/pages/login')
      }
    }
    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleValidateSession = () => {
    const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)

    if (storedToken) {
      return true 
    }
    return false
  }

  const handleLogin = async (params, errorCallback) => { 
    setLoading(true)
    let msg = 'Unable to login, please try again.';
    try {
      const res = await performLogin(params.email, params.password) 
      if(res && res.msg)      {
        msg = res.msg
      }

      if (res && res.error === 0) { 
        params.rememberMe = params.rememberMe ? window.localStorage.setItem(authConfig.storageTokenKeyName, res.token) : null 
        setUser({ ...res.userData })
        params.rememberMe = params.rememberMe ? window.localStorage.setItem('userData', JSON.stringify(res.userData)) : null
        
        return { status: true, msg: ''}
      }
    } catch (err) { 
      return { status: false, msg: err.message}
    }
    return { status: false, msg: msg }
  }

  const handleRegister = async (params, errorCallback) => {
    setLoading(true);
    let msg = "Unable to create account, please try again.";
    try {
      const res = await performRegister(params.firstName, params.lastName, params.email, params.password);
      console.log(res);

      if(Array.isArray(res)){
        msg = res[0].description;
      }


      if (res && res.msg) {
        msg = res.msg;
      }


      if (res && res.error === 0) {
        return { status: true, msg: "" };
      }
    } catch (err) {
      return { status: false, msg: err.message };
    }
    return { status: false, msg: msg };
  };

  const handleChangeProfileDetails = async (params, errorCallback) => {
    setLoading(true);
    try {
      const res = await changeProfileDetails(params);
      if (res && res.error === 0) {        
        setLoading(false);
        return { status: true, msg: "Profile details updated successfully" };
      }
    } catch (err) {
      setLoading(false);
      return { status: false, msg: err.message };
    }
  }

  const handleChangePassword = async (params, errorCallback) => {  
    setLoading(true);
    try {
      const res = await changePassword(params.email, params.currentpassword, params.newpassword);
      if (res && res.error === 0) {        
        setLoading(false);
        return { status: true, msg: "Password changed successfully" };
      }
    } catch (err) {
      setLoading(false);
      return { status: false, msg: err.message };
    }
  };

  const handleLogout = async (params, errorCallback) => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    return { status: true, msg: 'Logout success'}
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    register: handleRegister,
    logout: handleLogout,
    validateSession: handleValidateSession,
    changePassword: handleChangePassword,
    changeProfileDetails: handleChangeProfileDetails,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
